.article {
  color: #F2F2F2;
}

.article h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.02em;
  margin-top: 47px;
  scroll-margin-top: 100px;
}

.article h2:not(:first-child) {
  margin-top: 64px;
}

.article p, li, td, th {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.article p {
  margin-top: 32px;
}

.article h2+p {
  margin-top: 24px;
}

.article p:first-child {
  margin-top: 24px;
}

.article ul {
  padding-left: 27px;
}

.article figure {
  margin: 0;
}

.article img {
  width: 100%;
}

@media (max-width: 1280px) {
  .article table {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 900px) {
  .article table {
    margin-left: 0;
    margin-right: 0;
  }

  .article h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.2px;
    margin-top: 49px;
    margin-bottom: 12px;
  }

  .article p, li, td, th {
    font-size: 16px;
    letter-spacing: 0.12px; 
  }

  .article p {
    margin-top: 11px;
  }
}

.article a {
  color: #B875FF;
}

.article :focus-visible {
  outline: none;
  border: solid #FFFFFF 2px;
  border-radius: 6px;
  padding: 2px;
  margin: -4px;
}

@supports not selector(:focus-visible) {
  .article :focus {
    outline: none;
    border: solid #FFFFFF 2px;
    border-radius: 6px;
    padding: 2px;
    margin: -4px;
  }
}

@media (hover: hover) {
  .article a:hover {
    color: #935DEA;
  }
}

.article a:active {
  color: #8327D8;
}

.article .wp-block-table {
  overflow-x: auto;
}

.article table {
  width: 700px;
  border-spacing: 0;
}

.article td, th {
  vertical-align: top;
  border: 1px #303336;
  border-left-style: solid;
  border-bottom-style: solid;
  padding: 16px;
}

.article th {
  text-align: left;
  border-top-style: solid;
}

.article td:last-child, th:last-child {
  border-right-style: solid;
}

.article table.has-fixed-layout td:first-child, th:first-child {
  width: 235px;
}

.article table.has-fixed-layout td:last-child, th:last-child {
  width: 150px;
}

.article th:last-child {
  border-top-right-radius: 8px;
}

.article th:first-child {
  border-top-left-radius: 8px;
}

.article tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}
.article tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.article > ul {
  margin-top: 20px;
}

.article td ul {
  margin: 0;
}

.article > ul > li {
  margin-top: 8px;
}

.article p.has-text-align-center {
  text-align: center;
}

.article p.has-text-align-right {
  text-align: right;
}
