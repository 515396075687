@font-face {
  font-family: "Gordita";
  src: local("Gordita-Regular"),
    url("/fonts/Gordita-regular.woff2") format("woff2"),
    url("/fonts/Gordita-regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Gordita";
  src: local("Gordita-Medium"),
    url("/fonts/Gordita-medium.woff2") format("woff2"),
    url("/fonts/Gordita-medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Gordita";
  src: local("Gordita-Bold"),
    url("/fonts/Gordita-bold.woff2") format("woff2"),
    url("/fonts/Gordita-bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Gordita, Inter, sans-serif;
  background-color: #070518;
}

h1, h2, h3, p, div, span, button, input {
  font-family: Gordita, Inter, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

a, button, input {
  -webkit-tap-highlight-color: transparent;
}

body.disabled-scroll {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  top: var(--scroll-top-position);
}

::selection {
  color: #F2F2F2;
  background: #935DEA;
}
